import docReady from '@/js/helpers/doc-ready'

class Accordion {
  constructor (el) {
    this.el = el
    this.toggler = this.el.querySelector('[data-toggle]')
    this.active = false

    this.initEvents()
  }

  toggle () {
    this.active = !this.active

    this.el.classList.toggle('accordion-active', this.active)
  }

  initEvents () {
    this.toggler.addEventListener('click', this.toggle.bind(this))
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('.impact-item')).forEach(el => {
    // eslint-disable-next-line
    const accordion = new Accordion(el)
  })
})
