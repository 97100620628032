import docReady from '@/js/helpers/doc-ready'

class BackgroundSwitcher {
  constructor (el) {
    this.el = el
    this.switches = this.el.querySelectorAll('[data-change]')

    this.initEvents()
  }

  initEvents () {
    Array.from(this.switches).forEach(el => {
      const newBackground = el.getAttribute('data-change')
      el.addEventListener('mouseover', () => {
        this.el.classList.add('modifier-' + newBackground)
      })

      el.addEventListener('mouseout', () => {
        this.el.classList.remove('modifier-' + newBackground)
      })
    })
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-to-change]')).forEach(el => {
    // eslint-disable-next-line
    const backgroundSwitch = new BackgroundSwitcher(el)
  })
})
