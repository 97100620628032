import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

const axios = require('axios')
let isActive = false

const cb = () => {
  isActive = false
}

const emptyWrapper = wrapper => {
  if (wrapper.firstChild) {
    while (wrapper.firstChild) {
      wrapper.removeChild(wrapper.firstChild)
    }
  }
}

export const getPosts = (params, wrapper, cb) => {
  gsap.fromTo(wrapper, {
    opacity: 1
  }, {
    opacity: 0.8,
    duration: 0.2,
    onComplete: () => {
      axios
        .get(window.ajaxurl, {
          params
        })
        .then(res => res.data)
        .then(data => {
          gsap.set(wrapper, {
            opacity: 1
          })

          if (!params.paged && params.paged !== 0) {
            emptyWrapper(wrapper)
          } else {
            wrapper.querySelector('.navigation').remove()
          }

          Array.from(wrapper.querySelectorAll('.col')).forEach(el => {
            el.classList.add('loaded')
          })

          if (data) {
            wrapper.insertAdjacentHTML('beforeEnd', data)
          }

          const articles = wrapper.querySelectorAll('.col:not(.loaded)')

          if (articles && articles.length) {
            gsap.fromTo(articles, {
              opacity: 0,
              y: 10
            }, {
              opacity: 1,
              y: 0,
              duration: 0.4,
              stagger: 0.05
            })
          }
        })
        .catch(error => {
          // handle error
          console.log(error)
        })
        .then(cb)
    }
  })
}

let kennisgebiedId = 'all'
let postType = 'any'

const changeType = function (e, buttons, i, wrapper) {
  const curr = e.currentTarget
  if (curr.classList.contains('active') || isActive) {
    return
  }

  isActive = true

  for (let j = 0; j < buttons.length; j++) {
    if (i !== j) {
      buttons[j].classList.remove('active')
    } else {
      buttons[j].classList.add('active')
    }
  }

  kennisgebiedId = curr.getAttribute('data-term-id')
  postType = curr.getAttribute('data-post-type')
  paged = 1

  getPosts({
    action: 'load_items',
    kennisgebiedId,
    postType
  }, wrapper, cb)
}

let paged = 1

docReady(() => {
  const typeButtons = document.querySelectorAll('[data-post-type]')
  const ajaxPostsWrapper = document.getElementById('ajax-posts')

  if (typeButtons.length && ajaxPostsWrapper) {
    for (let i = 0; i < typeButtons.length; i++) {
      const clickHandler = e => changeType(e, typeButtons, i, ajaxPostsWrapper)
      typeButtons[i].addEventListener('click', clickHandler)
    }

    const docClickHandler = e => {
      if (e.target && e.target.hasAttribute('data-load-more')) {
        const currPostType = e.target.getAttribute('data-load-more')

        if (e.target.hasAttribute('data-term-id')) {
          kennisgebiedId = e.target.getAttribute('data-term-id')
        }

        paged++

        getPosts({
          action: 'load_items',
          kennisgebiedId,
          postType: currPostType,
          paged
        }, ajaxPostsWrapper)
      }
    }

    document.addEventListener('click', docClickHandler)
  }
})
