import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.l-content-cards__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      percentPosition: false,
      prevNextButtons: true,
      pageDots: false,
      wrapAround: false,
      groupCells: true,
      cellAlign: 'left'
    })
  })

  Array.from(document.querySelectorAll('.watch-slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      watchCSS: true,
      cellAlign: 'left',
      wrapAround: false,
      groupCells: true,
      prevNextButtons: false,
      pageDots: false
    })
  })

  Array.from(document.querySelectorAll('.l-partners__slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      // watchCSS: true,
      cellAlign: 'center',
      wrapAround: false,
      groupCells: true,
      prevNextButtons: false,
      pageDots: false
    })
  })
})
