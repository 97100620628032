import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

class Fullpage {
  constructor (el) {
    this.el = el
    this.open = this.el.querySelectorAll('[data-open]')
    this.close = this.el.querySelectorAll('[data-close]')
    this.bg = this.el.querySelectorAll('[data-bg]')
    this.content = this.el.querySelectorAll('[data-content]')
    this.slidesContainer = this.el.querySelector('[data-slides]')
    this.slides = this.el.querySelectorAll('[data-slide]')
    this.shapes = this.el.querySelectorAll('[data-shape]')
    this.blocks = this.el.querySelectorAll('[data-block]')
    this.nextButtons = this.el.querySelectorAll('[data-next]')
    this.active = false

    this.carouselPositions = null
    this.halfContainer = null
    this.currentItem = null

    this.initEvents()
  }

  async openFull () {
    this.active = true
    document.body.classList.add('body--fullpage-active')
    this.el.classList.add('fullpage-active')

    const tl = gsap.timeline({
      defaults: {
        duration: 0.4,
        ease: 'expo.out'
      }
    })

    tl.set(this.slidesContainer, {
      display: 'block',
      opacity: 0,
      y: '-100%'
    })

    tl.to(this.content, {
      opacity: 0.5
    })

    tl.set(document.body, {
      overflow: 'hidden'
    })

    await tl.to(this.slidesContainer, {
      duration: 0.8,
      opacity: 1,
      y: 0
    })

    this.getCarouselPositions()
  }

  closeFull () {
    this.active = false
    document.body.classList.remove('body--fullpage-active')
    this.el.classList.remove('fullpage-active')

    const tl = gsap.timeline({
      defaults: {
        duration: 0.4,
        ease: 'expo.out'
      }
    })

    tl.to(this.slidesContainer, {
      ease: 'expo.inOut',
      duration: 1.4,
      opacity: 0,
      y: '-100%',
      onComplete: () => {
        this.slidesContainer.scrollTo({
          top: 0
        })
      }
    })

    tl.to(this.content, {
      clearProps: 'opacity'
    })

    tl.set(this.slidesContainer, {
      display: 'none'
    })

    tl.set(document.body, {
      clearProps: 'overflow'
    })
  }

  getCarouselPositions () {
    this.carouselPositions = []
    Array.from(this.slides).forEach(slide => {
      this.carouselPositions.push([slide.offsetTop, slide.offsetTop + slide.offsetHeight])
      // add to array the positions information
    })
    this.halfContainer = window.innerHeight / 2
  }

  goCarousel () {
    const currentScrollTop = this.slidesContainer.scrollTop

    if (currentScrollTop === 0) {
      this.currentItem = 1
    } else {
      const currentMiddlePosition = currentScrollTop + this.halfContainer
      for (let i = 0; i < this.carouselPositions.length; i++) {
        if (currentMiddlePosition > this.carouselPositions[i][0] && currentMiddlePosition < this.carouselPositions[i][1]) {
          this.currentItem = i
          this.currentItem++
        }
      }
    }

    this.slidesContainer.scrollTo({
      top: this.carouselPositions[this.currentItem][0],
      behavior: 'smooth'
    })
  }

  initEvents () {
    Array.from(this.open).forEach(el => {
      el.addEventListener('click', this.openFull.bind(this))
    })
    Array.from(this.close).forEach(el => {
      el.addEventListener('click', this.closeFull.bind(this))
    })
    Array.from(this.nextButtons).forEach(el => {
      el.addEventListener('click', this.goCarousel.bind(this))
    })
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('.l-content-slides')).forEach(el => {
    // eslint-disable-next-line
    const fullpageSlider = new Fullpage(el)
  })
})
