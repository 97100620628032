import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  Array.from(document.querySelectorAll('[data-service]')).forEach(el => {
    el.addEventListener('click', () => {
      const topic = el.getAttribute('data-service')

      Array.from(document.querySelectorAll(`[data-service]:not([data-service="${topic}"])`)).forEach(item => {
        item.classList.remove('active')
      })

      Array.from(document.querySelectorAll(`[data-service="${topic}"]`)).forEach(item => {
        item.classList.add('active')
      })

      if (topic === 'all') {
        Array.from(document.querySelectorAll('[data-topic]')).forEach(item => {
          item.classList.remove('not-active')
        })
      } else {
        Array.from(document.querySelectorAll('[data-topic]').forEach(item => {
          const topicAttr = item.getAttribute('data-topic')
          const topicArr = topicAttr.split(',')
          let active = false

          topicArr.forEach(top => {
            if (top === topic) {
              active = true
            }
          })

          if (active) {
            item.classList.remove('not-active')
          } else {
            item.classList.add('not-active')
          }
        }))

        // Array.from(document.querySelectorAll(`[data-topic]:not([data-topic="${topic}"])`)).forEach(item => {
        //   item.classList.add('not-active')
        // })
        // Array.from(document.querySelectorAll(`[data-topic="${topic}"]`)).forEach(item => {
        //   item.classList.remove('not-active')
        // })
      }
    })
  })
})
