// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/navigation'
import '@/js/components/sliders'
import '@/js/components/map'
import '@/js/components/lazy-image'
import '@/js/components/accordion'
import '@/js/components/fullpage'
import '@/js/components/ajax'
import '@/js/components/services'
import '@/js/components/change-background'
